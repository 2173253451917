const session = {
  setItem(key: string, value: any) {
    if (typeof sessionStorage === 'undefined') return;
    sessionStorage?.setItem(key, JSON.stringify(value));
  },
  getItem(key: string) {
    if (typeof sessionStorage === 'undefined') return;
    const value = sessionStorage?.getItem(key);
    if (
      !value ||
      !/^[\],:{}\s]*$/.test(
        value
          .replace(/\\["\\\/bfnrtu]/g, '@')
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            ']',
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
      )
    )
      return value;
    return JSON.parse(value || '');
  },
  removeItem(key: string) {
    if (typeof sessionStorage === 'undefined') return;
    sessionStorage?.removeItem(key);
  },
};

const local = {
  setItem(key: string, value: any) {
    if (typeof localStorage === 'undefined') return;
    localStorage?.setItem(key, JSON.stringify(value));
  },
  getItem(key: string) {
    if (typeof localStorage === 'undefined') return;
    const value = localStorage?.getItem(key);

    if (
      !value ||
      !/^[\],:{}\s]*$/.test(
        value
          .replace(/\\["\\\/bfnrtu]/g, '@')
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            ']',
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
      )
    ) {
      return value;
    } else {
      return JSON.parse(value);
    }
  },
  removeItem(key: string) {
    if (typeof localStorage === 'undefined') return;
    localStorage?.removeItem(key);
  },
};

const searchStateStorage = (
  type: ProductType,
  value: HealthSearchCriteria | MotorSearchCriteria | PetSearchCriteria,
) => {
  const searchState = session.getItem(SEARCH_CRITERIA);
  session.setItem(SEARCH_CRITERIA, { ...searchState, [type]: value });
};

export { session, local, searchStateStorage };
