import { Box } from '@mui/material';
import Lottie from 'react-lottie';
import animationData from '@/assets/rebranding/Igloo.json';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  marginTop: '100px',
  height: '100vh',
  width: '100%',
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const CustomSpinner = () => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <StyledContainer>
      <Lottie
        options={defaultOptions}
        height="100%"
        width="100%"
        style={{
          maxWidth: '500px',
          width: '500px',
          height: isMobile ? '100%' : '300px',
          maxHeight: '300px',
        }}
      />
    </StyledContainer>
  );
};

export default CustomSpinner;
